export class HelperService {
    public getAncestorByComponentName(element, componentName: string) {
        let parent = element.$parent

        while (parent && parent.$options.name !== componentName) {
            parent = parent.$parent
        }

        return parent
    }

    public relativeUrl(url: string): string {
        const baseUrl = window.location.origin
        if (url.startsWith(baseUrl)) {
            url = url.replace(baseUrl, '')
        }
        return url
    }
}

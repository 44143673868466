<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name: 'Mails',
    extends: tablePageInit,
    setup() {
        useHead({
            title: 'Historique des déclarations',
            meta: [
                { name: 'description', content: 'Journal des modifications des déclarations.' },
            ],
        })
        definePageMeta({
            middleware: ['auth', 'has-territory'],
        })
    },
    data() {
        return {
            permissionName: 'changeLog',
            requestName: 'change_logs',
            defaultUrl: '/historique',
            translationName: 'historique',
            filtersOverload: ['declaration_revision', 'declaration_id', 'event_type', 'communes.id'],
            clickColumns: ['declaration_id'],
            columns: [
                { dataKey: 'revision' },
                { dataKey: 'declaration_id', template: [this.redirectToDeclaration] },
                { dataKey: 'event_type', transform: [this.toTranslate], template: [this.isChips, this.isChipsApp] },
                { dataKey: 'after_values', template: [this.generateDetailRow] },
                { dataKey: 'credentials.created_at', transform: [this.isDateTime] },
                { dataKey: 'credentials.created_by.name' },
                { dataKey: 'commune.name' },
            ],
            initSort: {
                // key : "commune",
                // order : "asc"
            },
        }
    },
    methods: {
        checkValuesNotEmpty(beforeValue, currentValue) {
            if (
                (!beforeValue || (Array.isArray(beforeValue) && !beforeValue.length) || beforeValue === '<p></p>')
                && (!currentValue || (Array.isArray(currentValue) && !currentValue.length) || currentValue === '<p></p>')
            ) {
                return false
            }
            return true
        },
        setupValue(value, key) {
            const translations = {
                in_accordance: 'Conformemement',
                despite_the_above_opinions: 'En dépit des avis ci-dessus',
                refused: 'Refusée',
                validate: 'Acceptée',
                physique: 'Personne physique',
                morale: 'Personne morale',
                created: 'Crée',
                in_progress: 'En cours',
                valid: 'Validée',
                invalid: 'Refusée',
                broussaille: 'Broussaille (ronce, fougère...)',
                jeune_boisement: 'Jeune boisement',
                boisement_adulte: 'Boisement adulte',
                vegetation_arbustive: 'Végétation arbustive',
                arbre_isole: 'Arbre isolé',
                pgp: 'Président de groupement pastoral',
                paf: 'Président d\'association foncière',
                elu: 'Elu',
                ind: 'Individuel',
                owner: 'Propriétaire',
                have_right: 'Ayant droit',
                etaf: 'Entreprise de travaux agricoles ou forestiers',
                water_union: 'Syndicat des rivières',
            }

            if (value === '' || value === '<p></p>')
                return '<i>texte vide</i>'

            if (!value)
                return '<i>aucune valeur</i>'

            if (typeof value === 'boolean')
                return value ? 'Oui' : 'Non'

            if (this.$translate(`declarations.${key}.${value}`) !== `declarations.${key}.${value}`) {
                return translations[value]
            }

            if (Array.isArray(value))
                return !value.length ? '<i>aucune valeur</i>' : value.join(', ')

            if (typeof value === 'object')
                return JSON.stringify(value)

            // CLEAN VALUE FROM TAGS TO HELP PRECONISATION DISPLAY
            if (typeof value === 'string') {
                const regex = /(<([^>]+)>)/g
                return value.replace(regex, '')
            }

            return value
        },
        isChipsApp(key, value, template, item) {
            let html = template
            html += `<br/><v-chip class="my-1" size="small">${this.$translate(`historique.model.${item.object_type}`)}</v-chip>`
            return html
        },
        clickColumn(event) {
            if (event.column === 'declaration_id' && event.item.type !== 'deleted')
                this.$router.push(`/declarations/${event.item.declaration_id}`)
        },
        generateDetailRow(key, value, template, item) {
            let html = ''
            if (value && typeof value === 'object') { //  && (item.event_type !== 'created' || item.object_type !== 'Declaration')
                const keys = Object.keys(value)
                // TRANSLATION PATH
                const path = item.object_type === 'Contact' ? 'utilisateurs.contact' : 'declarations'

                keys.forEach((key) => {
                    if (this.checkValuesNotEmpty(item.before_values[key], value[key])) {
                        html += `<div class="text-caption">${this.$translate(`${path}.${key}`)} : `

                        if (item.event_type !== 'created' && item.before_values.hasOwnProperty(key)) {
                            html += `de <span class="bg-deep-orange-lighten-4 pa-1 font-weight-bold">${this.setupValue(item.before_values[key], key)}</span> à `
                        }
                        html += `<span class="font-weight-bold ${item.event_type !== 'created' && 'bg-green-lighten-4 pa-1'}">${this.setupValue(value[key], key)}</span></div>`
                    }
                })
            }
            return html
        },
        redirectToDeclaration(key, value, template/* , item */) {
            return `<span class="text-info" style="cursor:pointer">${template}</span>`
        },
    },
}
</script>

<template>
    <div>
        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :filters-overload="filtersOverload"
            :translation-name="translationName"
            @load-data="loadData()"
            @reset-pagination="$refs.pagination.resetPagination()"
        />
        <v-card class="pa-5">
            <ListTable
                ref="table"
                :permission-name="permissionName"
                :translation-name="translationName"
                :sortable="sortable"
                :default-url="defaultUrl"
                :loading="loading"
                :data="data"
                :columns="columns"
                :init-sort="initSort"
                :click-columns="clickColumns"

                @click-column="clickColumn($event)"
                @reset-pagination="$refs.pagination.resetPagination()"
                @load-data="loadData()"
                @delete-data="deleteData($event)"
            />
            <div>
                <ListPagination
                    ref="pagination"
                    :total="total"
                    :loading="loading"
                    :last-page="lastPage"
                    @load-data="loadData"
                />
            </div>
        </v-card>
        <NuxtPage
            :request-name="requestName"
            :default-url="defaultUrl"
            :translation-name="translationName"
            :permission-name="permissionName"
            @load-data="loadData()"
        />
    </div>
</template>

<script lang="ts">
import { useConfigStore } from '~/store/index'

export default {
    name: 'Connexion',
    setup() {
        definePageMeta({ layout: 'empty', middleware: ['check-is-user'] })
        useHead({
            title: 'SERPIC | Connexion',
            meta: [
                { name: 'description', content: 'Connexion' },
            ],
        })
        const configStore = useConfigStore()
        return {
            configStore,
        }
    },
    data() {
        return {
            show_register_form: this.configStore.currentConfig.show_register_form,
        }
    },

}
</script>

<template>
    <div id="connexion" class="pa-0">
        <client-only>
            <v-row justify="center" align="start" class="pa-12">
                <div class="w-100 text-center">
                    <NuxtLink to="/">
                        <img class="mx-auto mb-8" src="@/assets/images/logo-cleaned.png" alt="Serpic logo" width="200">
                    </NuxtLink>
                </div>
                <v-col cols="12" sm="12" lg="5">
                    <v-card class="mx-auto px-10 py-5" elevation="8" max-width="500" rounded="lg">
                        <v-card-title class="pb-5 text-center">
                            J'ai déjà un compte SERPIC
                        </v-card-title>
                        <InfoCard text="<b>L'identifiant est maintenant l'e-mail de votre compte.</b>" level="warning" variant="flat" />
                        <ConnexionForm />
                    </v-card>
                </v-col>
                <v-col v-if="show_register_form" cols="12" sm="12" lg="7">
                    <v-card class="mx-auto px-10 py-5" elevation="8" rounded="lg">
                        <v-card-title class="pb-5 text-center px-0">
                            Je n'ai pas encore de compte
                        </v-card-title>
                        <v-card-subtitle>
                            <div class="text-caption font-italic text-center">
                                Remplissez et envoyez votre demande de création de compte pour que l'administrateur départemental de SERPIC puisse le valider. <br>
                                Vous pourrez ensuite créer vos déclarations pour la saison.
                            </div>
                        </v-card-subtitle>
                        <ConnexionAskAccount />
                    </v-card>
                </v-col>
            </v-row>
        </client-only>
    </div>
</template>

<style scoped>
#connexion {
    min-height: 101vh;
    background: rgb(2, 0, 36);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(9, 121, 56, 0.28895308123249297) 95%);
}
</style>
